import { styled } from '@mui/material';

import { OutlinedButton } from '../../Button/Button';

export const StyledButton = styled(OutlinedButton, {
  shouldForwardProp: (props) => props !== 'isMenuOpen',
})<{ isMenuOpen: boolean }>(({ isMenuOpen, theme }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  width: '100%',
  height: 'auto',
  maxHeight: 48,
  boxShadow: isMenuOpen ? '0px 1px 2px 0px rgba(0, 0, 0, 0.32)' : 'none',
  padding: theme.spacing(1.75),
  textWrap: 'noWrap',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[400],
  color: theme.palette.text.secondary,
  background: 'none',
  borderRadius: '4px',

  '&:hover': {
    backgroundColor: 'unset',
    color: 'unset',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[600],
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.32)',
  },
  '& > span': {
    marginRight: theme.spacing(0),
  },
}));

export const StyledButtonLabel = styled('p')(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  margin: 0,
}));
