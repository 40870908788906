import { styled } from '@mui/material';

import { TextButton } from '../Button/Button';

export const StyledCircledDiv = styled('div', {
  shouldForwardProp: (props) => props !== 'circled',
})<{ circled: boolean | undefined }>(({ circled, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  flexGrow: 0,
  background: circled ? theme.palette.common.white : 'inherit',
  borderRadius: circled ? '50%' : 'inherit',
  padding: circled ? theme.spacing(0.5) : 0,
  boxShadow: circled ? '0px 0px 6px rgba(0, 0, 0, 0.2)' : 'none',
}));

export const StyledButton = styled('button')(() => ({
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  padding: 0,
  color: 'inherit',
  fontFamily: 'inherit',
}));

export const StyledTextButton = styled(TextButton)(() => ({
  padding: 0,
  fontSize: 'inherit',
  '&:hover': {
    backgroundColor: 'inherit',
  },
}));
