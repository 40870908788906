import {
  type MenuProps,
  backdropClasses,
  Box,
  Divider,
  Fade,
  Menu,
  menuClasses,
  Slide,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import type { PopoverProps } from '@mui/material/Popover';
import type { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { SelectMenuItem } from './SelectMenuItem';

export type SelectProps = {
  values: SelectItem[];
  onValueChange: (value: KeyValue<string>) => void;
  anchorEl?: PopoverProps['anchorEl'];
  value?: string;
  canBeEmpty?: boolean;
  isLargeSize?: boolean;
  isHighlighted?: boolean;
  emptyLabel?: string;
  minWidth?: number;
} & MenuProps;

export interface SelectItem {
  name: string;
  value: string;
  items?: Array<KeyValue<string>>;
  extraInfo?: string;
}

const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    marginTop: theme.spacing(1),
    maxHeight: 279,

    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      left: '0 !important',
      top: 'unset !important',
      width: '100%',
      maxWidth: '100%',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  [`& .${backdropClasses.root}`]: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(0,0,0,0.45)',
      width: '100%',
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} timeout={300} />;
});

/**
 * @param values: SelectItem[]
 * @param onValueChange: (value: string) => void
 * @param grouped: boolean
 * @param props: SelectGroupedProps<T>
 */
export const Select = ({
  values,
  value,
  onValueChange,
  canBeEmpty,
  onClose,
  minWidth,
  emptyLabel,
  ...props
}: SelectProps): React.ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isNested = Boolean(
    values.filter((value: SelectItem) => value.items?.length).length
  );

  const handleSelection = (keyValue: KeyValue<string>) => {
    onValueChange(keyValue);
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  };

  return (
    <StyledMenu
      anchorReference="anchorEl"
      anchorOrigin={
        isMobile
          ? {
              vertical: 'bottom',
              horizontal: 'center',
            }
          : { vertical: 'bottom', horizontal: 'left' }
      }
      transformOrigin={
        isMobile
          ? {
              vertical: 'bottom',
              horizontal: 'center',
            }
          : { vertical: 'top', horizontal: 'left' }
      }
      elevation={isMobile ? 0 : 2}
      TransitionComponent={isMobile ? Transition : Fade}
      onClose={onClose}
      {...props}
    >
      {canBeEmpty ? (
        <SelectMenuItem
          onClick={() => handleSelection({ key: '', value: '' })}
          value=""
          selected={value === ''}
        >
          {emptyLabel || t('search.searchbar.select.pleaseChoose')}
        </SelectMenuItem>
      ) : (
        ''
      )}
      {values.map((group, groupIndex) => {
        const hasNested = group.items && group.items.length;
        return (
          <Box key={groupIndex} sx={{ minWidth: minWidth ? minWidth : 300 }}>
            <SelectMenuItem
              selected={value === group.value}
              value={group.value}
              onClick={() =>
                handleSelection({
                  key: group.name,
                  value: group.value,
                })
              }
            >
              {isNested ? (
                <Typography
                  variant={isMobile ? 'h5' : 'h4'}
                  component="span"
                  sx={{ padding: theme.spacing(0.5, 0) }}
                >
                  {group.name}
                </Typography>
              ) : (
                <Box>
                  {Boolean(group.name) && (
                    <Typography
                      variant="h6"
                      component="span"
                      sx={{ fontSize: { xs: '16px' } }}
                    >
                      {group.name}
                    </Typography>
                  )}
                  {Boolean(group.extraInfo) && (
                    <Typography
                      variant="subtitle1"
                      sx={{ pt: theme.spacing(1) }}
                    >
                      {group.extraInfo}
                    </Typography>
                  )}
                </Box>
              )}
            </SelectMenuItem>
            {hasNested ? (
              <Box
                sx={{
                  minWidth: '208px',
                }}
              >
                {group.items?.map((item, itemIndex) => (
                  <SelectMenuItem
                    selected={value === item.value}
                    value={item.value}
                    onClick={() =>
                      handleSelection({ key: item.key, value: item.value })
                    }
                    key={itemIndex}
                    sx={{
                      margin: 0,
                      paddingLeft: theme.spacing(7),
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: 16,
                        },
                      }}
                    >
                      {item.key}
                    </Typography>
                  </SelectMenuItem>
                ))}
              </Box>
            ) : (
              ''
            )}
            {!isMobile && isNested && groupIndex < values.length - 1 ? (
              <Box
                sx={{
                  m: 1,
                }}
              >
                <Divider sx={{ borderColor: theme.palette.grey[300] }} />
              </Box>
            ) : (
              ''
            )}
          </Box>
        );
      })}
    </StyledMenu>
  );
};
