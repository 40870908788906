import { Link, styled } from '@mui/material';
import React from 'react';

import { EVLogo } from '@/components/common/Icons/EVLogo';
import { useSettings } from '@/core/hooks/useSettings';

const StyledLink = styled(Link)(({ theme }) => ({
  lineHeight: 0,
  [theme.breakpoints.down('md')]: {
    flex: 1,
    textAlign: 'center',
  },
}));

const StyledEVLogo = styled(EVLogo)(({ theme }) => ({
  height: 'auto',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    height: '2.25rem',
    width: '9rem',
  },
}));

export const HeaderLogo: React.FC<{ className?: string }> = () => {
  const { headerLogoUrl } = useSettings();

  return (
    <StyledLink href={headerLogoUrl} aria-label="engel & völkers">
      <StyledEVLogo />
    </StyledLink>
  );
};
