import { type ParseKeys } from 'i18next';

import { MenuOptionsAlignment } from './enums';

export type MenuItem = {
  id: number;
  labelKey: ParseKeys<'header_footer'>;
  urlKey: ParseKeys<'header_footer'> | '';
  alignment?: MenuOptionsAlignment;
  labelKeyHasHTML?: boolean;
};

type NavItem = MenuItem & {
  menuItems: MenuItem[];
};

type NavigationMenu = {
  meta: {
    blog: NavItem;
    watchlist: NavItem;
  };
  main: {
    sell: NavItem;
    buy: NavItem;
    rent: NavItem;
    propertyPrices: NavItem;
    career: NavItem;
    service: NavItem;
    company: NavItem;
  };
};

export const navigationMenu: NavigationMenu = {
  meta: {
    blog: {
      id: 0,
      labelKey: 'menu.blog.label',
      urlKey: '',
      alignment: MenuOptionsAlignment.left,
      menuItems: [
        {
          id: 0,
          labelKey: 'menu.blog.luxuryLiving.label',
          urlKey: 'menu.blog.luxuryLiving.url',
        },
        {
          id: 1,
          labelKey: 'menu.blog.propertyInsights.label',
          urlKey: 'menu.blog.propertyInsights.url',
        },
        {
          id: 2,
          labelKey: 'menu.blog.interiorDesign.label',
          urlKey: 'menu.blog.interiorDesign.url',
        },
        {
          id: 3,
          labelKey: 'menu.blog.company.label',
          urlKey: 'menu.blog.company.url',
        },
      ],
    },
    watchlist: {
      id: 1,
      labelKey: 'menu.watchlist.label',
      urlKey: 'menu.watchlist.url',
      alignment: MenuOptionsAlignment.left,
      menuItems: [],
    },
  },
  main: {
    sell: {
      id: 0,
      labelKey: 'menu.sell.label',
      urlKey: 'menu.sell.url',
      alignment: MenuOptionsAlignment.left,
      menuItems: [
        {
          id: 0,
          labelKey: 'menu.sell.sellerAcquisition.label',
          urlKey: 'menu.sell.sellerAcquisition.url',
        },
        {
          id: 1,
          labelKey: 'menu.sell.evaluationTool.label',
          urlKey: 'menu.sell.evaluationTool.url',
        },
        {
          id: 2,
          labelKey: 'menu.sell.house.label',
          urlKey: 'menu.sell.house.url',
        },
        {
          id: 3,
          labelKey: 'menu.sell.apartment.label',
          urlKey: 'menu.sell.apartment.url',
        },
      ],
    },
    buy: {
      id: 1,
      labelKey: 'menu.buy.label',
      urlKey: '',
      alignment: MenuOptionsAlignment.right,
      menuItems: [
        {
          id: 0,
          labelKey: 'menu.buy.house.label',
          urlKey: 'menu.buy.house.url',
          labelKeyHasHTML: true,
        },
        {
          id: 1,
          labelKey: 'menu.buy.apartment.label',
          urlKey: 'menu.buy.apartment.url',
          labelKeyHasHTML: true,
        },
        {
          id: 2,
          labelKey: 'menu.buy.plot.label',
          urlKey: 'menu.buy.plot.url',
          labelKeyHasHTML: true,
        },
        {
          id: 3,
          labelKey: 'menu.buy.development.label',
          urlKey: 'menu.buy.development.url',
          labelKeyHasHTML: true,
        },
        {
          id: 4,
          labelKey: 'menu.buy.residentialInvestment.label',
          urlKey: 'menu.buy.residentialInvestment.url',
          labelKeyHasHTML: true,
        },
        {
          id: 5,
          labelKey: 'menu.buy.office.label',
          urlKey: 'menu.buy.office.url',
          labelKeyHasHTML: true,
        },
        {
          id: 6,
          labelKey: 'menu.buy.retailServices.label',
          urlKey: 'menu.buy.retailServices.url',
          labelKeyHasHTML: true,
        },
        {
          id: 7,
          labelKey: 'menu.buy.industrialSurface.label',
          urlKey: 'menu.buy.industrialSurface.url',
          labelKeyHasHTML: true,
        },
        {
          id: 8,
          labelKey: 'menu.buy.hotel.label',
          urlKey: 'menu.buy.hotel.url',
          labelKeyHasHTML: true,
        },
        {
          id: 9,
          labelKey: 'menu.buy.sailingYacht.label',
          urlKey: 'menu.buy.sailingYacht.url',
          labelKeyHasHTML: true,
        },
        {
          id: 10,
          labelKey: 'menu.buy.motorYacht.label',
          urlKey: 'menu.buy.motorYacht.url',
          labelKeyHasHTML: true,
        },
      ],
    },
    rent: {
      id: 2,
      labelKey: 'menu.rent.label',
      urlKey: '',
      alignment: MenuOptionsAlignment.right,
      menuItems: [
        {
          id: 0,
          labelKey: 'menu.rent.house.label',
          urlKey: 'menu.rent.house.url',
          labelKeyHasHTML: true,
        },
        {
          id: 1,
          labelKey: 'menu.rent.apartment.label',
          urlKey: 'menu.rent.apartment.url',
          labelKeyHasHTML: true,
        },
        {
          id: 2,
          labelKey: 'menu.rent.office.label',
          urlKey: 'menu.rent.office.url',
          labelKeyHasHTML: true,
        },
        {
          id: 3,
          labelKey: 'menu.rent.retailServices.label',
          urlKey: 'menu.rent.retailServices.url',
          labelKeyHasHTML: true,
        },
        {
          id: 4,
          labelKey: 'menu.rent.industrialSurface.label',
          urlKey: 'menu.rent.industrialSurface.url',
          labelKeyHasHTML: true,
        },
        {
          id: 5,
          labelKey: 'menu.rent.holidayhome.label',
          urlKey: 'menu.rent.holidayhome.url',
          labelKeyHasHTML: true,
        },
        {
          id: 6,
          labelKey: 'menu.rent.hotel.label',
          urlKey: 'menu.rent.hotel.url',
          labelKeyHasHTML: true,
        },
        {
          id: 7,
          labelKey: 'menu.rent.sailingYacht.label',
          urlKey: 'menu.rent.sailingYacht.url',
          labelKeyHasHTML: true,
        },
        {
          id: 8,
          labelKey: 'menu.rent.motorYacht.label',
          urlKey: 'menu.rent.motorYacht.url',
          labelKeyHasHTML: true,
        },
      ],
    },
    propertyPrices: {
      id: 6,
      labelKey: 'menu.propertyPrices.label',
      urlKey: '',
      alignment: MenuOptionsAlignment.left,
      menuItems: [
        {
          id: 0,
          labelKey: 'menu.propertyPrices.dropdown1.label',
          urlKey: 'menu.propertyPrices.dropdown1.url',
        },
        {
          id: 1,
          labelKey: 'menu.propertyPrices.dropdown2.label',
          urlKey: 'menu.propertyPrices.dropdown2.url',
        },
        {
          id: 2,
          labelKey: 'menu.propertyPrices.dropdown3.label',
          urlKey: 'menu.propertyPrices.dropdown3.url',
        },
        {
          id: 3,
          labelKey: 'menu.propertyPrices.dropdown4.label',
          urlKey: 'menu.propertyPrices.dropdown4.url',
        },
        {
          id: 4,
          labelKey: 'menu.propertyPrices.dropdown5.label',
          urlKey: 'menu.propertyPrices.dropdown5.url',
        },
        {
          id: 5,
          labelKey: 'menu.propertyPrices.dropdown6.label',
          urlKey: 'menu.propertyPrices.dropdown6.url',
        },
        {
          id: 6,
          labelKey: 'menu.propertyPrices.dropdown7.label',
          urlKey: 'menu.propertyPrices.dropdown7.url',
        },
        {
          id: 7,
          labelKey: 'menu.propertyPrices.dropdown8.label',
          urlKey: 'menu.propertyPrices.dropdown8.url',
        },
        {
          id: 8,
          labelKey: 'menu.propertyPrices.dropdown9.label',
          urlKey: 'menu.propertyPrices.dropdown9.url',
        },
      ],
    },
    career: {
      id: 3,
      labelKey: 'menu.career.label',
      urlKey: '',
      alignment: MenuOptionsAlignment.right,
      menuItems: [
        {
          id: 0,
          labelKey: 'menu.career.agent.label',
          urlKey: 'menu.career.agent.url',
        },
        {
          id: 1,
          labelKey: 'menu.career.jobOffers.label',
          urlKey: 'menu.career.jobOffers.url',
        },
        {
          id: 2,
          labelKey: 'menu.career.licencePartner.label',
          urlKey: 'menu.career.licencePartner.url',
        },
        {
          id: 3,
          labelKey: 'menu.career.academy.label',
          urlKey: 'menu.career.academy.url',
        },
      ],
    },
    service: {
      id: 4,
      labelKey: 'menu.services.label',
      urlKey: '',
      alignment: MenuOptionsAlignment.left,
      menuItems: [
        {
          id: 0,
          labelKey: 'menu.services.liquidHome.label',
          urlKey: 'menu.services.liquidHome.url',
        },
        {
          id: 1,
          labelKey: 'menu.services.finance.label',
          urlKey: 'menu.services.finance.url',
        },
      ],
    },
    company: {
      id: 5,
      labelKey: 'menu.company.label',
      urlKey: '',
      alignment: MenuOptionsAlignment.right,
      menuItems: [
        {
          id: 0,
          labelKey: 'menu.company.dropdown1.label',
          urlKey: 'menu.company.dropdown1.url',
        },
        {
          id: 1,
          labelKey: 'menu.company.dropdown2.label',
          urlKey: 'menu.company.dropdown2.url',
        },
        {
          id: 2,
          labelKey: 'menu.company.dropdown3.label',
          urlKey: 'menu.company.dropdown3.url',
        },
        {
          id: 3,
          labelKey: 'menu.company.dropdown4.label',
          urlKey: 'menu.company.dropdown4.url',
        },
        {
          id: 5,
          labelKey: 'menu.company.dropdown6.label',
          urlKey: 'menu.company.dropdown6.url',
        },
        {
          id: 6,
          labelKey: 'menu.company.dropdown7.label',
          urlKey: 'menu.company.dropdown7.url',
        },
      ],
    },
  },
};
