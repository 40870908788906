import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ArrowUpIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.001 7 20 15.289l-.714.711L12 8.451 4.715 16 4 15.289l8-8.289Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
