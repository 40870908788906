import { Card, CardContent, styled, Typography } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  width: 'auto',
  zIndex: 1001,
  cursor: 'default',
  [theme.breakpoints.up('md')]: {
    '&:before': {
      content: '""',
      height: 2,
      width: 190,
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: 0,
      right: 4,
    },
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(3, 0, 4, 0),
    display: 'block',
  },
})) as typeof Typography;

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 3),
  },
}));

export const StyledLabel = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(16),
  },
})) as typeof Typography; // workaround because of this type issue https://github.com/mui/material-ui/issues/19512
