import { type ReactElement, type SVGProps } from 'react';

export const HeartOutlined = (props: SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0799 7.55789C12.0399 7.61114 11.9601 7.61114 11.9201 7.55789L11.2005 6.60019C10.469 5.6266 9.30783 5 8 5C5.79086 5 4 6.79086 4 9C4 10.2788 4.56517 11.2303 4.76524 11.5554C6.41144 14.2304 9.33846 16.7164 12 18.7463C14.6615 16.7164 17.5886 14.2304 19.2348 11.5554C19.4348 11.2303 20 10.2788 20 9C20 6.79086 18.2091 5 16 5C14.6922 5 13.531 5.6266 12.7995 6.60019L12.0799 7.55789ZM12 20C9.19776 17.8983 5.79621 15.1387 3.91358 12.0795C3.68876 11.7141 3 10.5652 3 9C3 6.23858 5.23858 4 8 4C9.27052 4 10.4303 4.47388 11.3124 5.25453C11.5659 5.47885 11.7964 5.7285 12 5.99951C12.2036 5.7285 12.4341 5.47885 12.6876 5.25453C13.5697 4.47388 14.7295 4 16 4C18.7614 4 21 6.23858 21 9C21 10.5652 20.3112 11.7141 20.0864 12.0795C18.2038 15.1387 14.8022 17.8983 12 20Z"
        fill="currentColor"
      />
    </svg>
  );
};
