import { useMediaQuery, useTheme } from '@mui/material';
import dynamic from 'next/dynamic';
import React from 'react';
const FooterDesktop = dynamic(() =>
  import('./FooterDesktop/FooterDesktop').then((module) => module.FooterDesktop)
);
const FooterMobile = dynamic(() =>
  import('./FooterMobile/FooterMobile').then((module) => module.FooterMobile)
);
export const Footer = (): React.ReactElement => {
  const theme = useTheme();
  const desktopScreen = useMediaQuery(theme.breakpoints.up('md'));
  if (desktopScreen) {
    return <FooterDesktop />;
  } else {
    return <FooterMobile />;
  }
};
