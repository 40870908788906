import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const CheckIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.002 6.695 8.484 19l-5.482-5.388.707-.696 4.775 4.694L20.294 6l.708.695Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
