import { Box, Menu, MenuItem, styled } from '@mui/material';

export const StyledHeaderNavigationTitle = styled('button', {
  shouldForwardProp: (props) => props !== 'isToggled',
})<{ isToggled: boolean }>(({ isToggled, theme }) => ({
  background: 'none',
  border: 'none',
  textAlign: 'start',
  fontWeight: 700,
  textTransform: 'uppercase',
  fontSize: 14,
  letterSpacing: '0.03em',
  cursor: 'pointer',
  paddingLeft: 16,
  color: isToggled ? theme.palette.red[100] : theme.palette.neutral.dark,
  lineHeight: theme.spacing(3.125),
  fontFamily: 'inherit',
  '&:focus': {
    background: 'none',
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      color: theme.palette.red[100],
    },
  },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  marginTop: theme.spacing(1),
  maxHeight: theme.spacing(28.5),
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.MuiMenuItem-root': {
    fontSize: 16,
  },
  [theme.breakpoints.up('md')]: {
    '&.MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'end',
    },
  },
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

export const StyledNavigationItemContainer = styled(Box)(({ theme }) => ({
  textAlign: 'start',
  [theme.breakpoints.down('md')]: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[400],
    width: '100%',
    padding: '10px 24px',
  },
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'left',
  },
}));

export const StyledLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  width: '100%',
  textAlign: 'right',
  [theme.breakpoints.down('md')]: {
    textAlign: 'left',
  },
}));

export const StyledHeaderNavigationLink = styled('a')(({ theme }) => ({
  textAlign: 'start',
  fontWeight: 700,
  textTransform: 'uppercase',
  fontSize: 14,
  letterSpacing: '0.03em',
  cursor: 'pointer',
  paddingLeft: 16,
  color: theme.palette.neutral.dark,
  textDecoration: 'none',
  lineHeight: theme.spacing(3.125),
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      color: theme.palette.red[100],
    },
  },
}));
