import {
  type MenuItemProps,
  MenuItem,
  menuItemClasses,
  styled,
  useTheme,
} from '@mui/material';
import React from 'react';

import { CheckIcon } from '@/components/common/Icons/CheckIcon';

type SelectMenuItemProps = MenuItemProps<'li', { button?: true }>;

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(6),
  minHeight: 0,
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
  [`&.${menuItemClasses.selected}`]: {
    backgroundColor: theme.palette.grey[200],
    ['& :first-of-type']: {
      fontWeight: 500,
    },
  },
}));

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
}));

export const SelectMenuItem = ({
  children,
  className,
  ...props
}: SelectMenuItemProps): React.ReactElement => {
  const theme = useTheme();
  return (
    <StyledMenuItem disableGutters className={className} {...props}>
      {children}
      {props.selected && (
        <StyledCheckIcon color="primary" sx={{ ml: theme.spacing(2) }} />
      )}
    </StyledMenuItem>
  );
};
