import {
  type Currency,
  type Property,
  BusinessArea,
  Language,
  PropertyKind,
  PropertyMarketingType,
} from '@ev/search-modules-api';
import {
  type BusinessDivisionType,
  type UserActionEnum,
  getDivisionContext,
  getPageContext,
  getShopContext,
  trackUserInteractionEvent,
} from '@ev/snowplow-library';
import { type ParseKeys, type TFunction } from 'i18next';
import qs from 'qs';

import { EV_FINANCE_URL } from '@/components/common/Search/constants';
import { CommissionType } from '@/core/enums/commissionType';
import { Country } from '@/core/enums/country';
import {
  type ExtendedSortingOption,
  type WatchlistSortingOption,
} from '@/core/types/sort.types';
import type { PageTypeEnum } from '@/core/types/tracking.types';
import { getCurrentPriceOrRentProps } from '@/core/utils/getCurrentPriceOrRentProps';
import { formatNumber } from '@/core/utils/numberFormatters';
import { formatPriceWithDecimals } from '@/core/utils/priceFormatters';
import { getShopContextCommonValues } from '@/core/utils/tracking/tracking';

export const validatePropMinAndMax = (
  _min?: number,
  _max?: number
): { min?: number; max?: number } => {
  if (_min === undefined && _max) {
    return { max: _max };
  } else if (_max === undefined && _min) {
    return { min: _min };
  } else if (_min! > _max!) {
    return { min: _max, max: _min };
  }

  return { min: _min, max: _max };
};

export const validateRangeFieldNumber = (value: string): boolean => {
  const numberRegex = /^[1-9][0-9]*$/;
  return numberRegex.test(value.toString());
};

export const getSortByOptions = (
  t: TFunction<'common'>,
  marketingType: PropertyMarketingType,
  isCommercial: boolean
): Omit<ExtendedSortingOption, 'isRefined' | 'field'>[] => {
  let keyPrefix: 'price' | 'rent' = 'price';

  if (marketingType === PropertyMarketingType.rent) {
    keyPrefix = 'rent';
  }

  return [
    {
      sortingOption:
        marketingType === PropertyMarketingType.rent
          ? 'RENT_ASC'
          : 'SALES_PRICE_ASC',
      label: t(`search.searchbar.sortBy.${keyPrefix}Asc`),
    },
    {
      sortingOption:
        marketingType === PropertyMarketingType.rent
          ? 'RENT_DESC'
          : 'SALES_PRICE_DESC',
      label: t(`search.searchbar.sortBy.${keyPrefix}Desc`),
    },
    ...(isCommercial
      ? [
          {
            sortingOption: 'TOTAL_SURFACE_ASC' as WatchlistSortingOption,
            label: t('search.searchbar.sortBy.totalSurfaceAsc'),
          },
          {
            sortingOption: 'TOTAL_SURFACE_DESC' as WatchlistSortingOption,
            label: t('search.searchbar.sortBy.totalSurfaceDesc'),
          },
        ]
      : [
          {
            sortingOption: 'LIVING_SURFACE_ASC' as WatchlistSortingOption,
            label: t('search.searchbar.sortBy.livingAreaAsc'),
          },
          {
            sortingOption: 'LIVING_SURFACE_DESC' as WatchlistSortingOption,
            label: t('search.searchbar.sortBy.livingAreaDesc'),
          },
        ]),
    {
      sortingOption: 'PUBLISHED_AT_DESC',
      label: t('search.searchbar.sortBy.newestFirst'),
    },
  ];
};

export const isCommercialProperty = (property: Partial<Property>): boolean =>
  Boolean(property.businessArea?.includes(BusinessArea.commercial));

export const addParamsToUrl = (
  url: string,
  queryParams?: Record<string, unknown>
): string => {
  if (queryParams) {
    let queryString = qs.stringify(queryParams, {
      addQueryPrefix: !url.includes('?'),
    });
    if (url.includes('?')) {
      queryString = '&' + queryString;
    }
    return `${url}${queryString}`;
  }
  return url;
};

export const hasExtraPriceInfoDE = (property: Property): boolean => {
  const { utilitiesValue, rentNetValue } = getCurrentPriceOrRentProps(
    property,
    Language.en
  );
  return (
    property.countryAlpha2 === Country.DE &&
    property.marketingType === PropertyMarketingType.rent &&
    property.kind === PropertyKind.object &&
    (Boolean(utilitiesValue) || Boolean(rentNetValue))
  );
};

export const hasExtraPriceInfoAT = (property: Property): boolean => {
  const { utilitiesValue, rentNetValue } = getCurrentPriceOrRentProps(
    property,
    Language.en
  );
  return (
    property.countryAlpha2 === Country.AT &&
    property.marketingType === PropertyMarketingType.rent &&
    (Boolean(utilitiesValue) || Boolean(rentNetValue))
  );
};

export const hasExtraPriceInfoDK = (property: Property): boolean => {
  return (
    property.countryAlpha2 === Country.DK &&
    (Boolean(property.prices?.['DKK'].downPayment) ||
      Boolean(property.prices?.['DKK'].monthlyOwnerExpenses) ||
      Boolean(property.prices?.['DKK'].monthlyGrossPayment) ||
      Boolean(property.prices?.['DKK'].monthlyNetPayment) ||
      Boolean(property.prices?.['DKK'].pricePerSquareMeter))
  );
};

export const hasExtraPriceInfoByerTenantFR = (property: Property): boolean => {
  return (
    property.countryAlpha2 === Country.FR &&
    (property.commissionType === CommissionType.COMMISSION_MIXED ||
      property.commissionType === CommissionType.COMMISSION_BYER_TENANT_SIDE) &&
    Boolean(property.commissionPercent)
  );
};

export const hasExtraPriceInfoOwnerSellerFR = (property: Property): boolean => {
  return (
    property.countryAlpha2 === Country.FR &&
    property.commissionType === CommissionType.COMMISSION_OWNER
  );
};

export const hasCroatianRealEstateTax = (property: Property): boolean => {
  return property.countryAlpha2 === Country.HR && Boolean(property.propertyTax);
};

export const hasExtraPriceInfo = (property: Property): boolean => {
  return (
    hasExtraPriceInfoAT(property) ||
    hasExtraPriceInfoDE(property) ||
    hasExtraPriceInfoOwnerSellerFR(property) ||
    hasExtraPriceInfoByerTenantFR(property) ||
    hasExtraPriceInfoDK(property)
  );
};

// TODO: rename to hasExtraPriceInfo and delete hasExtraPriceInfo when new expose is fully implemented
export const hasExtraPriceInfoNew = (property: Property): boolean => {
  return (
    hasExtraPriceInfoAT(property) ||
    hasExtraPriceInfoDE(property) ||
    hasExtraPriceInfoDK(property)
  );
};

export const hasExtraPriceInfoFR = (property: Property): boolean => {
  return (
    hasExtraPriceInfoOwnerSellerFR(property) ||
    hasExtraPriceInfoByerTenantFR(property)
  );
};

export const getSpecificLivingAreaLabel = (
  property: Property
): ParseKeys<'common'> => {
  if (property.countryAlpha2 === Country.HK) {
    if (property.isDevelopmentProject) {
      return 'search.expose.propertyDetails.development.label.livingArea.hongKong';
    }
    return 'search.expose.propertyDetails.label.livingArea.hongKong';
  }
  if (property.isDevelopmentProject) {
    return 'search.expose.propertyDetails.development.label.livingArea';
  }

  return 'search.expose.propertyDetails.label.livingArea';
};

export const getEVFinancingURL = (
  displayID: string,
  country: Country,
  language: string,
  price?: number
): string => {
  let url;
  if (country === Country.ES) {
    switch (language) {
      case 'es':
        url = new URL(
          'https://ev-finance.es/es/financiacion?utm_source=engelvoelkers_website&utm_medium=online_expose'
        );
        break;
      case 'ca':
        url = new URL(
          'https://ev-finance.es/ca/financiacion?utm_source=engelvoelkers_website&utm_medium=online_expose'
        );
        break;
      case 'de':
        url = new URL(
          'http://ev-finance.es/de/finanzierung?utm_source=engelvoelkers_website&utm_medium=online_expose'
        );
        break;
      case 'en':
      default:
        url = new URL(
          'https://ev-finance.es/en/financing?utm_source=engelvoelkers_website&utm_medium=online_expose'
        );
        break;
    }
  } else {
    url = new URL(EV_FINANCE_URL as string);
  }

  url.searchParams.append('displayID', displayID);
  if (price) {
    url.searchParams.append('price', price.toString());
  }
  return url.toString();
};

export const financingImages: { [key: string]: string } = {
  es: 'https://uploadcare.engelvoelkers.com/300ff5a0-85fc-4012-bbef-b21c8cafbb0b/',
  de: 'https://uploadcare.engelvoelkers.com/93bb42e7-7a68-4283-ab00-355e503c1801/',
};

export const trackExposePageButtonClick = ({
  elementId,
  action,
  pageType,
  property,
}: {
  elementId: string;
  action: UserActionEnum;
  pageType: PageTypeEnum;
  property: Property;
}): void => {
  trackUserInteractionEvent({
    action: action,
    elementId: elementId,
    contexts: [
      getShopContext(getShopContextCommonValues(property)),
      getPageContext({
        type: pageType,
        isHQPage: true,
      }),
      getDivisionContext({
        name: property.businessArea as BusinessDivisionType,
      }),
    ],
  });
};

export type ExtraPriceInfo = {
  label: string;
  value?: string;
  convertedCurrencyLabel?: string;
};

const getDeAtExtraPriceInfo = (
  property: Property,
  languageCode: Language,
  t: TFunction,
  currency: Currency
) => {
  const extraPriceInfo: ExtraPriceInfo[] = [];

  const propertyPrice = getCurrentPriceOrRentProps(
    property,
    languageCode,
    property.baseCurrency as Currency
  );
  const totalRent = propertyPrice.value;
  const bareRent = propertyPrice.rentNetValue;
  const utilities = propertyPrice.utilitiesValue;

  const priceBasedOnUserCurrency = getCurrentPriceOrRentProps(
    property,
    languageCode,
    currency
  );

  const convertedPrice = priceBasedOnUserCurrency.value;
  const convertedUtilities = priceBasedOnUserCurrency.utilitiesValue;

  const germanAustriaCaseBareAndTotalRent =
    bareRent &&
    ((hasExtraPriceInfoDE(property) && totalRent) ||
      hasExtraPriceInfoAT(property));

  const germanAustriaCaseUtilities =
    utilities &&
    (hasExtraPriceInfoDE(property) || hasExtraPriceInfoAT(property));

  if (germanAustriaCaseBareAndTotalRent) {
    extraPriceInfo.push({
      label: t('search.price.label.rentNet') as string,
      value: bareRent,
      convertedCurrencyLabel:
        property.baseCurrency !== currency ? convertedPrice : '',
    });
  }

  if (germanAustriaCaseUtilities) {
    extraPriceInfo.push({
      label: t('search.price.label.monthlyUtilities') as string,
      value: utilities,
      convertedCurrencyLabel:
        property.baseCurrency !== currency ? convertedUtilities : '',
    });
  }

  return extraPriceInfo;
};

const getDkExtraPriceInfo = (
  property: Property,
  languageCode: Language,
  t: TFunction,
  currency: Currency
) => {
  const extraPriceInfo: ExtraPriceInfo[] = [];
  const downPayment = formatPriceWithDecimals(
    property.prices?.['DKK'].downPayment ?? 0,
    languageCode,
    property.baseCurrency ?? 'DKK'
  );
  const ownerExpenses = formatPriceWithDecimals(
    property.prices?.['DKK'].monthlyOwnerExpenses ?? 0,
    languageCode,
    property.baseCurrency ?? 'DKK'
  );
  const squareMeterPrice = formatPriceWithDecimals(
    property.prices?.['DKK'].pricePerSquareMeter ?? 0,
    languageCode,
    property.baseCurrency ?? 'DKK'
  );

  const convertedSquareMeterPrice = formatPriceWithDecimals(
    property.prices?.[currency].pricePerSquareMeter ?? 0,
    languageCode,
    currency
  );

  const grossPayment = formatNumber(
    property.prices?.['DKK'].monthlyGrossPayment ?? 0,
    languageCode
  );
  const netPayment = formatNumber(
    property.prices?.['DKK'].monthlyNetPayment ?? 0,
    languageCode
  );

  if (downPayment) {
    extraPriceInfo.push({
      label: t('search.price.label.downPayment') as string,
      value: downPayment,
    });
  }

  if (ownerExpenses) {
    extraPriceInfo.push({
      label: t('search.price.label.monthlyOwnerExpenses') as string,
      value: ownerExpenses,
    });
  }

  if (squareMeterPrice) {
    extraPriceInfo.push({
      label: t('search.price.label.pricePerSquareMeter') as string,
      value: `${squareMeterPrice}\u00A0/m\u00B2`,
      convertedCurrencyLabel:
        property.baseCurrency !== currency && convertedSquareMeterPrice
          ? `${convertedSquareMeterPrice}\u00A0/m\u00B2`
          : '',
    });
  }
  if (grossPayment && netPayment) {
    extraPriceInfo.push({
      label: t('search.price.label.grossNetMonthlyPayment') as string,
      value: `${grossPayment}\u00A0/\u00A0${netPayment}\u00A0${property.baseCurrency}`,
    });
  }
  return extraPriceInfo;
};

const getFrExtraPriceInfo = (property: Property, t: TFunction) => {
  const extraPriceInfo: ExtraPriceInfo[] = [];

  const frenchCommisionPercent = `${property.commissionPercent?.toString()} %`;
  const frenchCaseOwnerSeller = hasExtraPriceInfoOwnerSellerFR(property);
  const frenchCaseBuyerTenant = hasExtraPriceInfoByerTenantFR(property);

  if (frenchCaseBuyerTenant) {
    extraPriceInfo.push({
      label: t(
        'search.expose.propertyDetails.label.feesToBePaid.byerTenant'
      ) as string,
      value: frenchCommisionPercent,
    });
  }

  if (frenchCaseOwnerSeller) {
    extraPriceInfo.push({
      label: t(
        'search.expose.propertyDetails.label.feesToBePaid.ownerSeller'
      ) as string,
    });
  }
  return extraPriceInfo;
};

export const getExtraPriceInfo = (
  property: Property,
  languageCode: Language,
  t: TFunction,
  currency: Currency
): ExtraPriceInfo[] => {
  if (hasExtraPriceInfoDE(property) || hasExtraPriceInfoAT(property)) {
    return getDeAtExtraPriceInfo(property, languageCode, t, currency);
  }

  if (
    hasExtraPriceInfoByerTenantFR(property) ||
    hasExtraPriceInfoOwnerSellerFR(property)
  ) {
    return getFrExtraPriceInfo(property, t);
  }

  if (hasExtraPriceInfoDK(property)) {
    return getDkExtraPriceInfo(property, languageCode, t, currency);
  }

  return [];
};
