import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const GlobeIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Zm1.246-1.976A8.016 8.016 0 0 0 4 12c0 .69.088 1.36.252 2h15.496c.165-.64.252-1.31.252-2 0-.682-.085-1.344-.246-1.976H4.246Zm.326-1h14.856a8.003 8.003 0 0 0-14.856 0ZM12 20a8.003 8.003 0 0 1-7.418-5h14.837A8.003 8.003 0 0 1 12 20Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.938 5.813C9.535 4.231 10.52 3 12 3c1.476 0 2.48 1.226 3.096 2.808.628 1.617.943 3.81.943 6.192s-.315 4.575-.943 6.192C14.48 19.774 13.476 21 12 21c-1.48 0-2.465-1.231-3.062-2.813-.61-1.616-.907-3.808-.907-6.187 0-2.38.297-4.57.907-6.187Zm.935.353c-.55 1.46-.842 3.519-.842 5.834 0 2.315.291 4.374.842 5.834C10.437 19.328 11.186 20 12 20c.818 0 1.583-.677 2.164-2.17.567-1.46.875-3.518.875-5.83 0-2.312-.308-4.37-.875-5.83C13.583 4.678 12.818 4 12 4c-.814 0-1.563.672-2.127 2.166Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
