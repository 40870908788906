import { useEffect, useState } from 'react';

import { getTrackingQueryParams } from '@/core/utils/tracking/tracking';

export const useQueryParams = () => {
  const [trackingQueryParams, setTrackingQueryParams] = useState<
    Record<string, unknown> | undefined
  >();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const queryParams = getTrackingQueryParams(currentUrl);
    setTrackingQueryParams(queryParams);
  }, []);

  return trackingQueryParams;
};
