import type { SvgIconProps } from '@mui/material/SvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export const ArrowDownIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m19.999 8.711-8 8.289L4 8.711 4.714 8l7.285 7.549L19.285 8l.714.711Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
