import { Grid, styled, Typography } from '@mui/material';

import { Divider } from '@/components/common/Divider/Divider';
import { PinIconMapCard as PinIcon } from '@/components/common/Icons/PinIconMapCard';
import { LinkWithParams } from '@/components/elements';

import { CARD_HEIGHT } from './PropertyCard';

export const StyledLabelTranslation = styled(Grid)(() => ({
  overflowY: 'hidden',
  textOverflow: 'ellipsis',
}));

export const StyledText = styled(Grid)(() => ({
  textAlign: 'right',
}));

export const StyledDividerMap = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
}));

export const StyledFactRow = styled(Grid)(({ theme }) => ({
  alignItems: 'baseline',
  marginBottom: theme.spacing(0.5),
}));

export const StyledFacts = styled(Grid)(() => ({
  alignSelf: 'flex-start',
}));

export const StyledLocationText = styled(Typography)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})) as typeof Typography;

export const StyledTitle = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'relative',
}));

export const StyledThumbnail = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  verticalAlign: 'text-top',
  minWidth: theme.spacing(16.25),
  width: theme.spacing(16.25),
  height: theme.spacing(11),
  minHeight: theme.spacing(11),
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    minHeight: 'auto',
  },
}));

export const StyledPinIcon = styled(PinIcon)(({ theme }) => ({
  height: 13,
  width: theme.spacing(1.25),
  marginRight: theme.spacing(0.75),
}));

export const StyledHeader = styled('div')(({ theme }) => ({
  background: theme.palette.grey[100],
  padding: `7.5px ${theme.spacing(1)}`,
  fontSize: theme.spacing(1.5),
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[600],
}));

export const StyledLink = styled(LinkWithParams)(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));

export const StyledMapCard = styled('div')<{ isSingle: boolean }>(
  ({ isSingle, theme }) => ({
    display: 'block',
    padding: theme.spacing(),
    minWidth: theme.spacing(40.5),
    height: CARD_HEIGHT,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      scrollSnapAlign: 'center',
      minWidth: 'initial',
    },
    width: !isSingle ? '85vw' : 'inherit',
    [theme.breakpoints.up('sm')]: {
      width: !isSingle ? 'auto' : 0,
      boxShadow: !isSingle ? 'none' : 'inherit',
      minWidth: !isSingle ? 'auto' : theme.spacing(40.5),
      border: !isSingle ? `1px solid ${theme.palette.grey[300]}` : 'inherit',
      borderBottom: !isSingle ? 'none' : 'inherit',
      '&:last-child': {
        border: !isSingle ? `1px solid ${theme.palette.grey[300]}` : 'inherit',
      },
    },
  })
);
