import { useContext } from 'react';

import {
  type WatchlistContextType,
  WatchlistContext,
} from '@/core/providers/WatchlistProvider';

export const useWatchlist = (): WatchlistContextType => {
  const ctx = useContext(WatchlistContext);
  if (ctx === undefined) {
    throw new Error('useWatchlist used outside of ContextProvider!');
  } else {
    return ctx;
  }
};
