import { type SxProps, type Theme, Box } from '@mui/material';
import React, { type KeyboardEvent, useCallback } from 'react';

import { ArrowDownIcon } from '@/components/common/Icons/ArrowDownIcon';
import { ArrowUpIcon } from '@/components/common/Icons/ArrowUpIcon';

import { type SelectProps, Select } from './Select';
import { StyledButton, StyledButtonLabel } from './SelectField.styles';

type SelectFieldProps = {
  SelectProps: Omit<SelectProps, 'open'>;
  label: string;
  dataCy?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export const SelectField = ({
  disabled,
  label,
  dataCy,
  sx,
  SelectProps,
}: SelectFieldProps): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const isOpen = Boolean(anchorEl);
  const open = useCallback(
    (e: React.MouseEvent | React.KeyboardEvent) =>
      !disabled && setAnchorEl(e.currentTarget),
    [disabled]
  );

  return (
    <Box>
      <StyledButton
        isMenuOpen={isOpen}
        onClick={open}
        onKeyDown={(e: KeyboardEvent) => e.key === 'Enter' && open(e)}
        endIcon={
          isOpen ? (
            <ArrowUpIcon color={disabled ? 'disabled' : 'primary'} />
          ) : (
            <ArrowDownIcon color={disabled ? 'disabled' : 'secondary'} />
          )
        }
        sx={sx}
        data-cy={dataCy}
      >
        <StyledButtonLabel>{label}</StyledButtonLabel>
      </StyledButton>
      {/* Workaround for this bug NS-2166/NS-2163/NS-2164 */}
      {isOpen && (
        <Select
          sx={{
            cursor: 'default',
            '&:hover': {
              cursor: 'default',
            },
          }}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          {...SelectProps}
          open={isOpen}
        />
      )}
    </Box>
  );
};
