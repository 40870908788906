import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const PinIconMapCard = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M1.52597 1.79373C2.45808 0.924363 3.70903 0.5 5 0.5C6.29097 0.5 7.54192 0.924363 8.47402 1.79373C9.41135 2.66798 10 3.96814 10 5.66133C10 7.37832 9.18974 8.92911 8.2077 10.2282C7.35882 11.3511 6.35449 12.3198 5.57795 13.0687C5.4557 13.1867 5.33909 13.2991 5.22962 13.4059C5.10095 13.5314 4.89904 13.5314 4.77038 13.4059C4.66091 13.2991 4.5443 13.1867 4.42205 13.0687C3.64552 12.3198 2.64118 11.3511 1.7923 10.2282C0.810255 8.92911 0 7.37832 0 5.66133C0 3.96814 0.588645 2.66798 1.52597 1.79373ZM1.97434 2.30009C1.18934 3.03225 0.666667 4.1417 0.666667 5.66133C0.666667 7.15715 1.37507 8.56008 2.31902 9.80879C3.13305 10.8856 4.09529 11.8141 4.87362 12.5652C4.91632 12.6064 4.95846 12.6471 5 12.6872C5.04153 12.6471 5.08368 12.6064 5.12638 12.5652C5.90471 11.8141 6.86695 10.8856 7.68097 9.80879C8.62493 8.56008 9.33333 7.15714 9.33333 5.66133C9.33333 4.1417 8.81066 3.03225 8.02566 2.30009C7.23544 1.56305 6.15305 1.18421 5 1.18421C3.84695 1.18421 2.76456 1.56305 1.97434 2.30009ZM5 3.86156C4.10663 3.86156 3.41176 4.56369 3.41176 5.39359C3.41176 6.22349 4.10663 6.92563 5 6.92563C5.89337 6.92563 6.58823 6.22349 6.58823 5.39359C6.58823 4.56369 5.89337 3.86156 5 3.86156ZM2.7451 5.39359C2.7451 4.15337 3.77086 3.17735 5 3.17735C6.22914 3.17735 7.2549 4.15337 7.2549 5.39359C7.2549 6.63381 6.22914 7.60984 5 7.60984C3.77086 7.60984 2.7451 6.63381 2.7451 5.39359Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
