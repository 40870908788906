import { Popover, styled } from '@mui/material';

export const StyledButton = styled('button')(({ theme }) => ({
  color: theme.palette.grey[900],
  display: 'flex',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: 0,
  alignItems: 'center',
  marginRight: theme.spacing(3),
  fontFamily: 'inherit',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({
  pointerEvents: 'none',
  marginTop: theme.spacing(1.25),
  '& .MuiPopover-paper': {
    pointerEvents: 'auto',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    width: 320,
    maxWidth: 390,
  },
}));
