import { styled } from '@mui/material';

export const StyledNavWrapper = styled('nav')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledWrapper = styled('div')(() => ({
  justifyContent: 'flex-end',
  display: 'flex',
  flexFlow: 'row wrap',
  width: '100%',
  alignItems: 'center',
}));
